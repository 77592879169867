import React, { useState, useEffect } from 'react';
import style from './Simmilar.module.css';
import BASE_URL from "../config"; // 모듈 CSS 임포트

function Simmilar() {
    const [students, setStudents] = useState([]);

    useEffect(() => {
        const fetchProducts = () => {
            fetch(`${BASE_URL}/get_users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    setStudents(data); // 가져온 학생 정보를 상태에 저장
                })
                .catch((error) => console.error("Fetching error:", error));
        };

        fetchProducts(); // 컴포넌트가 마운트될 때 한 번만 실행
    }, []);

    return (
        <div className={style['profile-body']}>
            <p className={style.title}>관심사가 비슷한 학생</p>
            <div className={style.profiles}>
                {
                    students.map((std, index) => (
                        <div key={index} className={style.std}>
                            <img className={style['std_image']} src={std.img} alt={`${std.name}의 사진`} />
                            <p className={style['std_name']}>{std.name}</p>
                            <p className={style['std_school']}>{std.school}</p>
                            <p className={style['std_tel']}>{std.tel}</p>
                            <p className={style['std_birth']}>{std.birth}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Simmilar;
