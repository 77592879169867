import React, {useEffect, useState} from 'react';
import style from './MyProfile.module.css'
import BASE_URL from '../config';

function MyProfile(){

    const [id, SetID] = useState('yangwoochan68@gmail.com')
    const [name, SetName] = useState('양우찬')
    const [school, SetSchool] = useState('해킹방어과 1학년')
    const [tel, SetTel] = useState('010-4522-1461')
    const [birth, SetBirth] = useState('2008-01-25')
    const [career, SetCareer] = useState(['해킹방어과 1학년 입학', '잠원중학교 졸업', 'NYPC TOP 500', '정보처리기능사 합격'])
    const [img, SetImg] = useState("")

    useEffect(() => {
        // Fetch API를 사용하여 상품정보를 비동기적으로 가져오는 함수
        const fetchProducts = () => {
            fetch(`${BASE_URL}/get_my_info`, {
                method : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    id:id
                })
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    // setStudents(data); // 가져온 상품정보를 상태에 저장
                    console.log(data)
                    SetName(data.name)
                    SetSchool(data.school)
                    SetTel(data.tel)
                    SetBirth(data.birth)
                    SetImg(data.img)
                    SetCareer(JSON.parse(data.career.replace(/'/g, '"')))
                })
                .catch((error) => console.error("Fetching error:", error));
        };

        // 컴포넌트 마운트 시 상품정보 가져오기 함수 호출
        fetchProducts();
    }, []); // 컴포넌트가 마운트될 때 한 번만 실행

    return (
        <div className={style['profile-body']}>
            <p className={style.title}>나의 프로필</p>
            <div className={style.profile}>
                <img className={style['profile-image']} src={img} alt={`${name}의 프로필`} />
                <div className={style.info}>
                    <b className={style.name}>{name}</b>
                    <p className={style.school}>{school}</p>
                    <p className={style.tel}>연락처 {tel}</p>
                    <p className={style.birth}>생일 {birth}</p>
                    <div className={style.career}>
                        {career.map((car, index) => (
                            <p key={index}>{car}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyProfile;