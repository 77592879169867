import { BrowserRouter, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import TopBar from './TopBar/TopBar'
import MyProfile from "./MyProfile/MyProfile";
import Simmilar from "./Simmilar/Simmilar";
import Login from "./Login/Login";
import Logout from "./Login/Logout";
import Search from "./Search/Search";
import {useEffect} from "react";
import PrivateRoute from "./PrivateRoute/PrivateRoute";

function Main() {
    return(
        <div className='App'>
            <TopBar/>
            <MyProfile/>
            <Simmilar/>
            <Search/>
        </div>
    )
}

function App() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login/>}></Route>
                <Route path="/logout" element={<Logout/>}></Route>
                <Route element={<PrivateRoute/>}>
                    <Route path="/" element={<Main/>}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
