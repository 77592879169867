import React, {useEffect, useState} from 'react'
import style from './Search.module.css'
import BASE_URL from '../config';

function Search(){

    const [students, setStudents] = useState([]);
    const [filtered_students, setFilteredStudents] = useState([]);
    let str_search = "";

    useEffect(() => {
        const fetchProducts = () => {
            fetch(`${BASE_URL}/get_users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    setStudents(data); // 가져온 학생 정보를 상태에 저장
                    setFilteredStudents(data)
                })
                .catch((error) => console.error("Fetching error:", error));
        };

        fetchProducts(); // 컴포넌트가 마운트될 때 한 번만 실행
    }, []);

    const handleValueChange = (e) => {
        str_search = e.target.value

        if(!str_search){
            setFilteredStudents(students)
        }
        else{
            setFilteredStudents(students.filter(user =>
                user.name.includes(str_search) // 이름에 검색어가 포함된 경우
            ))
        }
    }

    return(
        <div className={style.body}>
            <p className={style.title}>전체 학생</p>
            <div className={style.inputbox}>
                <img className={style.img_search} src="https://cdn.icon-icons.com/icons2/1129/PNG/512/searchmagnifierinterfacesymbol1_79893.png"/>
                <input className={style.input} type="text" onChange={(e) => handleValueChange(e)}/>
            </div>
            <div className={style.profiles}>
                {
                    filtered_students.map((std, index) => (
                        <div key={index} className={style.std}>
                            <img className={style['std_image']} src={std.img} alt={`${std.name}의 사진`} />
                            <p className={style['std_name']}>{std.name}</p>
                            <p className={style['std_school']}>{std.school}</p>
                            <p className={style['std_tel']}>{std.tel}</p>
                            <p className={style['std_birth']}>{std.birth}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Search;