import React from 'react';
import style from './TopBar.module.css';

function TopBar() {
    return (
        <div className={style.topbar}>
            <div className={style.logo}>
                <p>SNNECT</p>
            </div>
            <nav className={style.navbar}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/logout">Logout</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
            </nav>
        </div>
    );
}

export default TopBar;
