import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import BASE_URL from '../config';

const PrivateRoute = () => {
    const [isLogin, setIsLogin] = useState(null); // 상태를 초기화
    const [loading, setLoading] = useState(true); // 로딩 상태

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem("token");

            if (token) {
                try {
                    const response = await fetch(`${BASE_URL}/check_login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        setIsLogin(true); // 로그인 성공
                    } else {
                        setIsLogin(false); // 로그인 실패
                    }
                } catch (error) {
                    console.error('Token validation error:', error);
                    setIsLogin(false); // 오류 발생 시 로그인 실패로 처리
                }
            } else {
                setIsLogin(false); // 토큰이 없을 경우 로그인 실패
            }

            setLoading(false); // 로딩 완료
        };

        validateToken(); // 토큰 검증 함수 호출
    }, []);

    if (loading) {
        return <div>Loading...</div>; // 로딩 중일 때 표시할 내용
    }

    return isLogin ? <Outlet /> : <Navigate to="/login" />; // 로그인 상태에 따라 라우팅
};

export default PrivateRoute;
